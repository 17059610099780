<template lang="pug">
  v-card
      div(v-if="showTable")
        TableWrapperCsvImport(
          :showSelect="true"
          :csv="employeesCsv"
          :columsOrder="columsOrder"
          :showDeleteButton="true"
          :tableLength="employeesTable.length"
          :isCanAddNewOption="true"
          :table="cloneTableForFilter"
          :onSaveEvent="onSaveEvent"
          :options="options"
          :onSaveTableSteps="onSaveTableSteps"
          :hideImportAndAddEmployeeBtn="true"
          @on-close-order-modal="closeUploadCsvMode"
          @on-update-table="updateTable"
          @on-update-options="updateOptions"
          @on-remove-many-rows="onRemoveManyRows"
          @is-error-mode="toggleErrorMode"
          @delete-row="onDeleteRow"
        )
          v-card-head(slot='head')
            v-card-head-label
              h2.title {{ 'ui.labels.employees'  | translate}}
              p.subtitle {{ 'infotext.here_you_can_manage_employees' | translate }}
          div(slot="accept")
            v-btn(@click="openTableSteps")  {{ 'ui.buttons.save' | translate }}

</template>

<script>
import api from '@/api'
import { mapGetters, mapActions } from 'vuex'
import TableWrapperCsvImport from '@/components/ui/Table/TableWrapperCsvImport'
import uuidv1 from 'uuid/v1'

export default {
  name: 'ImportCsv',

  components: {
    TableWrapperCsvImport
  },

  mounted () {
    this.showTable = true
  },

  data: () => ({
    uploadCsvMode: false,
    employeesTable: [],
    cloneTableForFilter: [],
    options: {
      position: [],
      department: [],
      location: [],
      role: []
    },
    showTable: false,
    selectedFilterOption: { name: 'Show all', key: 'show_all' },
    filterOptions: [{ name: 'Show all', key: 'show_all' }, { name: 'Show only warnings', key: 'show_warnings' }],
    columsOrder: [
      { value: 'First name', key: 'firstName', type: 'text', id: uuidv1(), sorted: true, sortIncrease: false },
      { value: 'Last name', key: 'lastName', type: 'text', id: uuidv1(), sorted: true, sortIncrease: false },
      { value: 'E-mail', key: 'email', type: 'text', id: uuidv1(), sorted: true, sortIncrease: false },
      { value: 'Position', key: 'position', type: 'select', id: uuidv1(), sorted: true, sortIncrease: false },
      { value: 'Department', key: 'department', type: 'select', id: uuidv1(), sorted: true, sortIncrease: false },
      { value: 'Location', key: 'location', type: 'select', id: uuidv1(), sorted: true, sortIncrease: false },
      { value: 'Role', key: 'role', type: 'multiselect', id: uuidv1(), sorted: false, sortIncrease: false },
      { value: 'Active', key: 'active', type: 'switch', style: 'width:60px;', id: uuidv1(), sorted: true, sortIncrease: false }
    ],
    onSaveEventFunction: () => {}
  }),

  methods: {
    ...mapActions('setupWizard', ['setEmployeesCsv']),
    ...mapActions('app', ['setPopupMessage']),
    ...mapActions('employees', ['loadEmployees']),
    updateOptions (options) {
      this.options = options
    },

    toggleErrorMode (param) {
      if (param) {
        this.cloneTableForFilter = this.employeesTable.filter(el => el.hasError)
      } else {
        this.cloneTableForFilter = [...this.employeesTable]
      }
    },

    openInput () {
      this.$refs.uploadCsv.click()
    },

    tooltipClasses (str) {
      return str.length > 16 ? [] : ['tooltip--hidden']
    },

    onRemoveManyRows () {
      this.employeesTable = this.employeesTable.filter(el => !el.selected)
      this.cloneTableForFilter = [...this.employeesTable]
    },

    onDeleteRow (id) {
      this.employeesTable.splice(this.employeesTable.findIndex(el => el.id === id), 1)
      this.cloneTableForFilter.splice(this.cloneTableForFilter.findIndex(el => el.id === id), 1)
    },

    updateTable (table) {
      let cloneTable = [...table]
      this.employeesTable.unshift(...cloneTable)
      this.cloneTableForFilter.unshift(...cloneTable)
    },

    onSaveEvent (func) {
      this.onSaveEventFunction = func
    },

    openTableSteps () {
      this.onSaveEventFunction()
    },

    async onSaveTableSteps () {
      let requestTable = this.employeesTable.map(el => {
        return {
          employee: {
            firstName: el.firstName,
            lastName: el.lastName,
            email: el.email,
            active: el.active
          },
          role: el.role.map(item => { return { name: item ? item.name : '' } }),
          department: { name: el.department ? el.department.name : '' },
          location: { name: el.location ? el.location.name : '' },
          position: { name: el.position ? el.position.name : '' }
        }
      })
      const { data } = await api.importEmployees(requestTable)
      if (data.length && data.flat().filter(el => !!(el.errors || []).length).length) {
        data.forEach((el, index) => {
          if (el.length) {
            this.employeesTable[index].hasError = true
            this.employeesTable[index].errors = el
          } else {
            this.employeesTable[index].hasError = false
            this.employeesTable[index].errors = []
          }
        })
        this.employeesTable.forEach(el => { el.selected = false })
        this.cloneTableForFilter = [...this.employeesTable]
        return true
      } else {
        // successsfull
        await this.loadEmployees({ isPushed: false })
        this.closeUploadCsvMode()
        this.setPopupMessage({
          message: this.$t('infotext.count_employees_has_imported', { count: this.employeesTable.length })
        })

        return false
      }
    },

    async closeUploadCsvMode () {
      await this.$router.push('/administration/employees')
      this.setEmployeesCsv(null)
    }
  },

  computed: {
    ...mapGetters('setupWizard', [
      'employeesCsv'
    ]),

    importPath () {
      return this.$route.path
    }

  },

  watch: {
    importPath (value) {
      if (value !== '/administration/employees/import' && this.employeesCsv) {
        this.employeesTable = []
        this.cloneTableForFilter = []
        this.showTable = false
      } else if (value === '/administration/employees/import') {
        this.showTable = true
      }
    }
  }
}

</script>

<style lang="scss" scoped>
</style>
